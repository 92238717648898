@import '@angular/cdk/overlay-prebuilt.css';
@import 'modern-normalize/modern-normalize.css'; // css reset
@import '@cds/core/global.min.css'; // clarity global styles
@import '@cds/city/css/bundles/default.min.css'; // load base font


:root {
  --tui-heading-font: 'Metropolis';
  --tui-text-font: 'Metropolis';
  --clr-header-bg-color: 'white';
}

:root[cds-theme~=phs] header .header-nav .nav-icon>cds-icon,
:root[cds-theme~=phs] .header .header-nav .nav-icon>cds-icon,
:root[cds-theme~=phs] header .header-actions .nav-icon>cds-icon,
:root[cds-theme~=phs] .header .header-actions .nav-icon>cds-icon {
  color: var(--turquoise-darkest);
}

.modal-body-wrapper {
  width: 100% !important;
}

tui-root {
  display: block;
  height: 100%;
}

.footer {
  position: relative;
}

.footer_version {
  color: lightgrey;
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer_version p.p7 {
  color: silver;
}

// fjerner trakten i datagrid
.datagrid-placeholder-image {
  background-image: none !important;
  //background-image: url(http://ndl.mgccw.com/mu3/app/20140113/11/1389584149638/icon_n/icon_xl.png) !important;
}
